z
<template>
    <footer class="tp-footer">
        <div class="tp-footer__container container">
            <div class="tp-footer__inner">
                <div class="tp-footer__inner-box tp-footer__head">
                    <a
                        class="tp-footer__logo"
                        href="/">
                        <img
                            src="@/assets/images/logo.png"
                            alt="" />
                    </a>
                    <div class="tp-footer__head-text">
                        <p>TROPIC-TOUR - фирма, успешно осуществляющая туроператорскую и агентскую деятельность. Компания с безупречной репутацией, великолепным наследием и перспективным будущим!</p>
                    </div>
                    <div class="tp-footer__head-follow">
                        <h3 class="tp-footer__title">Подписаться на рассылку</h3>
                        <div class="tp-footer__follow-input">
                            <input
                                placeholder="Ваша почта"
                                type="text"
                                name="footer__follow"
                                id="footer__follow" />
                            <img
                                src="@/assets/icons/icon-mail.svg"
                                alt="email" />
                        </div>
                    </div>
                </div>
                <div class="tp-footer__inner-box tp-footer__nav">
                    <h3 class="tp-footer__title">Карта сайта</h3>
                    <div class="tp-footer__nav-links">
                        <a
                            href="/"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon"
                                src="@/assets/icons/icon-arrow.svg"
                                alt="arrow" />
                            Главная
                        </a>
                        <a
                            href="/tours"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon"
                                src="@/assets/icons/icon-arrow.svg"
                                alt="" />
                            Все туры
                        </a>
                        <a
                            href="/news"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon"
                                src="@/assets/icons/icon-arrow.svg"
                                alt="" />
                            Блог
                        </a>
                        <a
                            href="/contacts"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon"
                                src="@/assets/icons/icon-arrow.svg"
                                alt="" />
                            Контакты
                        </a>
                        <a
                            href="/visasupport"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon"
                                src="@/assets/icons/icon-arrow.svg"
                                alt="" />
                            Визовая поддержка
                        </a>
                        <a
                            href="/team"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon"
                                src="@/assets/icons/icon-arrow.svg"
                                alt="" />
                            Наша команда
                        </a>
                    </div>
                </div>
                <div class="tp-footer__inner-box tp-footer__nav">
                    <h3 class="tp-footer__title">Полезные сслыки</h3>
                    <div class="tp-footer__nav-links">
                        <a
                            href="https://www.uzairways.com/ru"
                            target="_blank"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon"
                                src="@/assets/icons/icon-arrow.svg"
                                alt="" />
                            Авиабилеты
                        </a>
                        <a
                            href="https://railway.uz/ru/"
                            target="_blank"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon"
                                src="@/assets/icons/icon-arrow.svg"
                                alt="" />
                            ЖД Билеты
                        </a>
                        <a
                            href="https://www.booking.com/"
                            target="_blank"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon"
                                src="@/assets/icons/icon-arrow.svg"
                                alt="" />
                            Бронирование отелей
                        </a>
                    </div>
                </div>
                <div class="tp-footer__inner-box tp-footer__contact">
                    <h3 class="tp-footer__title">Связаться</h3>
                    <div class="tp-footer__nav-links">
                        <div class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon tp-contacticon"
                                src="@/assets/icons/icon-location.svg"
                                alt="" />
                            Мирзо Улугбекский район. Буюк ипак йули. дом 48, квартира 29
                        </div>
                        <a
                            href="info@tropic-tour.uz"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon tp-contacticon"
                                src="@/assets/icons/icon-mail.svg"
                                alt="" />
                            info@tropic-tour.uz
                        </a>
                        <a
                            href="tel:+998996336888"
                            class="tp-footer__nav-item">
                            <img
                                class="tp-footer__link-icon tp-contacticon"
                                src="@/assets/icons/icon-phone.svg"
                                alt="" />
                            +998 (99) 633 68 88
                        </a>
                    </div>

                    <div class="tp-footer__media">
                        <a
                            class="tp-footer__media-item"
                            href="#!">
                            <img
                                class="tp-footer__media-icon"
                                src="@/assets/icons/icon-instagram.svg"
                                alt="social instagram" />
                        </a>
                        <a
                            class="tp-footer__media-item"
                            href="#!">
                            <img
                                class="tp-footer__media-icon"
                                src="@/assets/icons/icon-facebook.svg"
                                alt="social facebook" />
                        </a>
                        <a
                            class="tp-footer__media-item"
                            href="#!">
                            <img
                                class="tp-footer__media-icon"
                                src="@/assets/icons/icon-tiktok.svg"
                                alt="social tiktok" />
                        </a>
                    </div>
                    <CompButton
                        tpbtnText="Забронировать тур"
                        @click.native="
                            () => {
                                openModal({
                                    title: 'Хотите забронировать тур?',
                                    url: '/form-main',
                                    form: 'Форма забронировать тур',
                                    desc: 'Мы в процессе сбора доступных для бронирования туров, для ознакомления и бронирования заполните поля ниже и отправьте форму. Наши специалисты свяжутся с вами и предложат туры для бронирования',
                                });
                            }
                        "
                        :tpbtnUid="'button-footer-main'" />
                </div>
            </div>
        </div>
        <div class="tp-footer__rights">
            <p>
                (c) 2024 Tropic Tour - Ваш надежный путеводитель в мир путешествий!.
                <br />
                Все права защищены.
            </p>
        </div>
    </footer>
</template>

<script>
import {mapActions} from "vuex";
import CompButton from "@/components/compButton.vue";

export default {
    name: "component-footer",
    components: {
        CompButton,
    },
    methods: {
        ...mapActions(["openModal"]),
    },
};
</script>
