var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"tp-popcontact",attrs:{"id":"button-form-follower"},on:{"click":() => {
            _vm.openModal({
                title: 'Оставить заявку',
                url: '/form-main',
                form: 'Форма преследующей кнопки',
            });
        }}},[_c('img',{staticClass:"tp-popcontact__image",attrs:{"src":require("@/assets/icons/icon-cphone.svg"),"alt":"contact"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }