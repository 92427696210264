<template>
    <div>
        <MainHeader />
        <RouterView class="tp-body" />
        <MainFooter class="tp-footer" />
        <CompLeaveapp />
        <CompFollowform />
        <PageLoader
            v-if="isPageLoading"
            :class="{none: !isShown}"
            class="tp-loader" />
    </div>
</template>

<script>
import CompLeaveapp from "@/components/compLeaveapp.vue";
import CompFollowform from "@/components/compFollowform.vue";
import MainHeader from "@/components/compHeader.vue";
import MainFooter from "@/components/compFooter.vue";
import PageLoader from "@/components/compLoader.vue";

export default {
    name: "tp-layout",
    components: {
        CompLeaveapp,
        CompFollowform,
        MainHeader,
        MainFooter,
        PageLoader,
    },
    data() {
        return {
            isPageLoading: true,
            isShown: true,
        };
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            this.isPageLoading = true;
            this.isShown = true;

            setTimeout(() => {
                document.title = "Tropic Tour | " + (to.meta.title || "Путешевствовать с нами легко");
                next();
            }, 300);
        });

        this.$router.afterEach(() => {
            setTimeout(() => {
                this.isShown = false;
                setTimeout(() => {
                    this.isPageLoading = false;
                }, 400);
            }, 300);
        });
    },
};
</script>
<style>
.tp-body {
    z-index: 1;
}
</style>
