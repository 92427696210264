import Vue from "vue";
import VueRouter from "vue-router";
// import {component} from "vue/types/umd";

// import comload from "@/components/compConstruction.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "*",
        name: "payment",
        meta: {title: "Упс"},
        component: () => import(/* webpackChunkName: "notfound" */ "@/views/payment/payment.vue"),
    },
];

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
    mode: "history",
    base: process.env.BASE_URL,
    routes: [...routes],
});

export default router;
