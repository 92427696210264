<template>
    <div class="tp-loader">
        <div class="tp-loader__body">
            <div class="tp-loader__circle circle-front"></div>
            <div class="tp-loader__circle circle-rear"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "section-loader"
};
</script>
