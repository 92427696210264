<template>
    <header
        class="tp-header__wrapper"
        :class="{scrolled: isScrolled, opened: isMobile}">
        <div
            ref="tpTopHeader"
            class="tp-topheader">
            <div class="tp-topheader__content container">
                <div class="tp-topheader__contact">
                    <div class="tp-topheader__contact-label">
                        <img
                            class="tp-topheader__contact-icon"
                            src="@/assets/icons/icon-phone.svg"
                            alt="icon" />
                        <p class="tp-topheader__contact-text">Телефон:</p>
                    </div>
                    <div class="tp-topheader__contact-list">
                        <a
                            class="tp-topheader__contact-el"
                            href="tel:+998(78) 122 20 23">
                            +998(78) 122 20 23
                        </a>
                        <a
                            class="tp-topheader__contact-el"
                            href="tel:+998(99) 633 68 88">
                            +998(99) 633 68 88
                        </a>
                    </div>
                </div>
                <div class="tp-topheader__contact">
                    <div class="tp-topheader__contact-label">
                        <img
                            class="tp-topheader__contact-icon"
                            src="@/assets/icons/icon-mail.svg"
                            alt="icon" />
                        <p class="tp-topheader__contact-text">Почта:</p>
                    </div>
                    <div class="tp-topheader__contact-list">
                        <a
                            class="tp-topheader__contact-el"
                            href="mailto:support@tropic-tour.uz">
                            support@tropic-tour.uz
                        </a>
                    </div>
                </div>
                <div class="tp-topheader__socials">
                    <a
                        class="tp-topheader__social"
                        href="https://www.instagram.com/tropictour_uz"
                        target="_blank">
                        <img
                            class="tp-topheader__social-img"
                            src="@/assets/icons/icon-instagram.svg"
                            alt="social icon" />
                    </a>
                    <a
                        class="tp-topheader__social"
                        href="https://www.facebook.com/profile.php?id=61550594882318"
                        target="_blank">
                        <img
                            class="tp-topheader__social-img"
                            src="@/assets/icons/icon-facebook.svg"
                            alt="social icon" />
                    </a>
                    <a
                        class="tp-topheader__social"
                        href="https://www.tiktok.com/@tropictour.uz"
                        target="_blank">
                        <img
                            class="tp-topheader__social-img"
                            src="@/assets/icons/icon-tiktok.svg"
                            alt="social icon" />
                    </a>
                </div>
            </div>
        </div>
        <div
            ref="tpHeader"
            class="tp-header">
            <div class="tp-header__content container">
                <router-link
                    to="/"
                    class="tp-header__logo">
                    <img
                        class="tp-header__logo-image"
                        src="@/assets/images/logo.png"
                        alt="logo" />
                </router-link>

                <nav class="tp-header__nav">
                    <ul class="tp-header__nav-list">
                        <li
                            v-for="(item, index) in links"
                            :key="'elem' + index"
                            class="tp-navlink">
                            <component
                                :is="item.link ? 'router-link' : 'a'"
                                :to="item.link"
                                class="tp-navlink__label">
                                {{ item.label }}
                            </component>
                            <ul
                                v-if="item.children"
                                class="tp-navlink tp-dropdown">
                                <li
                                    v-for="(inneritem, index) in item.children"
                                    :key="'subelem' + index"
                                    class="tp-navlink tp-sublink">
                                    <component
                                        :is="inneritem.link ? 'router-link' : 'a'"
                                        :to="inneritem.link"
                                        class="tp-navlink__label tp-sublink__label">
                                        {{ inneritem.label }}
                                    </component>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>

                <div class="tp-header__action">
                    <CompButton
                        @click.native="
                            () => {
                                openModal({title: 'Оставить заявку', url: '/form-main'});
                            }
                        "
                        class="tp-header__action-btn"
                        :tpbtnText="'Оставить заявку'"
                        :tpbtnUid="'button-header-main'" />
                </div>

                <div class="tp-header__mobile">
                    <div
                        @click="isMobile = !isMobile"
                        class="tp-header__mobile-btn"></div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import {mapActions} from "vuex";
import CompButton from "@/components/compButton.vue";

export default {
    name: "component-header",
    components: {CompButton},
    data() {
        return {
            isScrolled: false,
            isMobile: false,
            links: [
                {
                    label: "главная",
                    link: "/",
                },
                {
                    label: "о нас",
                    link: "",
                    children: [
                        {
                            label: "новости",
                            link: "/news",
                        },
                        {
                            label: "наша команда",
                            link: "/team",
                        },
                        {
                            label: "отзывы",
                            link: "/reviews",
                        },
                        {
                            label: "наша история",
                            link: "/history",
                        },
                    ],
                },
                {
                    label: "туры",
                    link: "",
                    children: [
                        {
                            label: "Все туры",
                            link: "/tours/alltours",
                        },
                        {
                            label: "Сезонные туры",
                            link: "/tours/seasontours",
                        },
                        {
                            label: "Горячие туры",
                            link: "/tours/toptours",
                        },
                        {
                            label: "Экскурсионные туры",
                            link: "/tours/excursiontours",
                        },
                    ],
                },
                {
                    label: "услуги",
                    link: "",
                    children: [
                        {
                            label: "Визовая поддержка",
                            link: "/visasupport",
                        },
                        {
                            label: "Индивидуальный подбор тура",
                            link: "/individuals",
                        },
                        {
                            label: "Корпоративные поездки",
                            link: "/corporate",
                        },
                    ],
                },
                {
                    label: "контакты",
                    link: "/contacts",
                },
            ],
        };
    },
    computed: {
        headerHeight() {
            return this.$refs.tpHeader?.offsetHeight || 0;
        },
        topheaderHeight() {
            return this.$refs.tpTopHeader?.offsetHeight || 0;
        },
        screenWidth() {
            return window.innerWidth;
        },
    },
    mounted() {
        ["mousewheel", "scroll"].forEach((evt) => window.addEventListener(evt, this.handleScroll, false));
    },
    methods: {
        ...mapActions(["openModal"]),
        handleScroll() {
            let appearAt = this.topheaderHeight + this.headerHeight;
            let removeAt = this.topheaderHeight;

            if (this.screenWidth < 577) return;
            if (this.screenWidth < 992) {
                appearAt = this.headerHeight;
                removeAt = 0;
            }

            if (window.pageYOffset > appearAt) {
                this.isScrolled = true;
            } else if (this.isScrolled && window.pageYOffset <= appearAt && window.pageYOffset > removeAt) {
                return;
            } else {
                this.isScrolled = false;
            }
        },
        openMobile() {
            this.isMobile = !this.isMobile;
        },
    },
    watch: {
        $route() {
            this.isMobile = false;
        },
    },
};
</script>
