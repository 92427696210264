<template>
    <BaseLayout>
        <RouterView />
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/layout/BaseLayout.vue";

export default {
    name: "App",
    components: {
        BaseLayout,
    },
    beforeMount() {
        const currentDomain = window.location.hostname;
        if (currentDomain.endsWith(".com")) {
            // if (currentDomain) {
            const script = document.createElement("script");
            const script2 = document.createElement("script");
            const script3 = document.createElement("script");
            const script4 = document.createElement("script");
            const script5 = document.createElement("script");
            const script6 = document.createElement("script");

            script.src = "https://yandex.ru/ads/system/context.js";
            script.async = true;
            script.crossOrigin = "anonymous";

            script2.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8417240893449828";
            script2.async = true;

            script3.type = "text/javascript";
            script3.innerHTML = "window.yaContextCb=window.yaContextCb||[]";

            script4.type = "text/javascript";
            script4.innerHTML = 'window.yaContextCb.push(() => {Ya.Context.AdvManager.render({"blockId": "R-A-11857196-1","renderTo": "yandex_rtb_R-A-11857196-1"})})';

            script5.type = "text/javascript";
            script5.innerHTML = "var infolinks_pid = 3425560; var infolinks_wsid = 0";

            script6.src = "//resources.infolinks.com/js/infolinks_main.js";
            script6.async = true;

            document.head.appendChild(script);
            document.head.appendChild(script2);
            document.head.appendChild(script3);
            document.body.appendChild(script4);
            document.body.appendChild(script5);
            document.body.appendChild(script6);
        }
    },
};
</script>
