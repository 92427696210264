var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"tp-footer"},[_c('div',{staticClass:"tp-footer__container container"},[_c('div',{staticClass:"tp-footer__inner"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"tp-footer__inner-box tp-footer__contact"},[_c('h3',{staticClass:"tp-footer__title"},[_vm._v("Связаться")]),_vm._m(3),_vm._m(4),_c('CompButton',{attrs:{"tpbtnText":"Забронировать тур","tpbtnUid":'button-footer-main'},nativeOn:{"click":function($event){return (() => {
                            _vm.openModal({
                                title: 'Хотите забронировать тур?',
                                url: '/form-main',
                                form: 'Форма забронировать тур',
                                desc: 'Мы в процессе сбора доступных для бронирования туров, для ознакомления и бронирования заполните поля ниже и отправьте форму. Наши специалисты свяжутся с вами и предложат туры для бронирования',
                            });
                        }).apply(null, arguments)}}})],1)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tp-footer__inner-box tp-footer__head"},[_c('a',{staticClass:"tp-footer__logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":""}})]),_c('div',{staticClass:"tp-footer__head-text"},[_c('p',[_vm._v("TROPIC-TOUR - фирма, успешно осуществляющая туроператорскую и агентскую деятельность. Компания с безупречной репутацией, великолепным наследием и перспективным будущим!")])]),_c('div',{staticClass:"tp-footer__head-follow"},[_c('h3',{staticClass:"tp-footer__title"},[_vm._v("Подписаться на рассылку")]),_c('div',{staticClass:"tp-footer__follow-input"},[_c('input',{attrs:{"placeholder":"Ваша почта","type":"text","name":"footer__follow","id":"footer__follow"}}),_c('img',{attrs:{"src":require("@/assets/icons/icon-mail.svg"),"alt":"email"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tp-footer__inner-box tp-footer__nav"},[_c('h3',{staticClass:"tp-footer__title"},[_vm._v("Карта сайта")]),_c('div',{staticClass:"tp-footer__nav-links"},[_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"/"}},[_c('img',{staticClass:"tp-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-arrow.svg"),"alt":"arrow"}}),_vm._v(" Главная ")]),_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"/tours"}},[_c('img',{staticClass:"tp-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-arrow.svg"),"alt":""}}),_vm._v(" Все туры ")]),_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"/news"}},[_c('img',{staticClass:"tp-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-arrow.svg"),"alt":""}}),_vm._v(" Блог ")]),_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"/contacts"}},[_c('img',{staticClass:"tp-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-arrow.svg"),"alt":""}}),_vm._v(" Контакты ")]),_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"/visasupport"}},[_c('img',{staticClass:"tp-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-arrow.svg"),"alt":""}}),_vm._v(" Визовая поддержка ")]),_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"/team"}},[_c('img',{staticClass:"tp-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-arrow.svg"),"alt":""}}),_vm._v(" Наша команда ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tp-footer__inner-box tp-footer__nav"},[_c('h3',{staticClass:"tp-footer__title"},[_vm._v("Полезные сслыки")]),_c('div',{staticClass:"tp-footer__nav-links"},[_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"https://www.uzairways.com/ru","target":"_blank"}},[_c('img',{staticClass:"tp-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-arrow.svg"),"alt":""}}),_vm._v(" Авиабилеты ")]),_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"https://railway.uz/ru/","target":"_blank"}},[_c('img',{staticClass:"tp-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-arrow.svg"),"alt":""}}),_vm._v(" ЖД Билеты ")]),_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"https://www.booking.com/","target":"_blank"}},[_c('img',{staticClass:"tp-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-arrow.svg"),"alt":""}}),_vm._v(" Бронирование отелей ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tp-footer__nav-links"},[_c('div',{staticClass:"tp-footer__nav-item"},[_c('img',{staticClass:"tp-footer__link-icon tp-contacticon",attrs:{"src":require("@/assets/icons/icon-location.svg"),"alt":""}}),_vm._v(" Мирзо Улугбекский район. Буюк ипак йули. дом 48, квартира 29 ")]),_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"info@tropic-tour.uz"}},[_c('img',{staticClass:"tp-footer__link-icon tp-contacticon",attrs:{"src":require("@/assets/icons/icon-mail.svg"),"alt":""}}),_vm._v(" info@tropic-tour.uz ")]),_c('a',{staticClass:"tp-footer__nav-item",attrs:{"href":"tel:+998996336888"}},[_c('img',{staticClass:"tp-footer__link-icon tp-contacticon",attrs:{"src":require("@/assets/icons/icon-phone.svg"),"alt":""}}),_vm._v(" +998 (99) 633 68 88 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tp-footer__media"},[_c('a',{staticClass:"tp-footer__media-item",attrs:{"href":"#!"}},[_c('img',{staticClass:"tp-footer__media-icon",attrs:{"src":require("@/assets/icons/icon-instagram.svg"),"alt":"social instagram"}})]),_c('a',{staticClass:"tp-footer__media-item",attrs:{"href":"#!"}},[_c('img',{staticClass:"tp-footer__media-icon",attrs:{"src":require("@/assets/icons/icon-facebook.svg"),"alt":"social facebook"}})]),_c('a',{staticClass:"tp-footer__media-item",attrs:{"href":"#!"}},[_c('img',{staticClass:"tp-footer__media-icon",attrs:{"src":require("@/assets/icons/icon-tiktok.svg"),"alt":"social tiktok"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tp-footer__rights"},[_c('p',[_vm._v(" (c) 2024 Tropic Tour - Ваш надежный путеводитель в мир путешествий!. "),_c('br'),_vm._v(" Все права защищены. ")])])
}]

export { render, staticRenderFns }